import React from 'react'
import Layout from '../components/_App/Layout'
import Seo from '../components/_App/Seo'
import NavbarTwo from '../components/_App/NavbarTwo'
import PageBanner from '../components/Common/PageBanner'
import Partner from '../components/Common/Partner'

// Images 
import testimonialImg from '../assets/images/testimonial/tc2.jpg'
import testimonialImg2 from '../assets/images/testimonial/tc3.jpg'
import testimonialImg3 from '../assets/images/testimonial/tc4.jpg'
import testimonialImg4 from '../assets/images/testimonial/tc5.jpg'
import testimonialImg5 from '../assets/images/testimonial/tc6.jpg'
import testimonialImg6 from '../assets/images/testimonial/tc7.jpg'
import Footer from '../components/_App/Footer'
import Copyright from '../components/_App/Copyright'

const TestimonialPage = () => {
    return (
        <Layout>
            <Seo title="Testimonials" />

            <NavbarTwo />

            <PageBanner 
                pageTitle="Client Feedbacks" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Testimonials" 
            />

            <section className="testimonial pt-70 pb-100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="testimonial-card-2">
                                <div className="testimonial-card-text-2">
                                    <i className="flaticon-right-quotes-symbol"></i>
                                    <p>“Had a fantastic time at Glit, got to meet a lot of great people and hear some amazing talks. Thanks @glit for a super day!”</p>
                                </div>
                                <div className="testimonial-card-2-intro">
                                    <img 
                                        src={testimonialImg} 
                                        alt="testimonial"
                                    />
                                    <h4>Robert Cook</h4>
                                    <p>Front End Developer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="testimonial-card-2">
                                <div className="testimonial-card-text-2">
                                    <i className="flaticon-right-quotes-symbol"></i>
                                    <p>“Had a fantastic time at Glit, got to meet a lot of great people and hear some amazing talks. Thanks @glit for a super day!”</p>
                                </div>
                                <div className="testimonial-card-2-intro">
                                    <img 
                                        src={testimonialImg2} 
                                        alt="testimonial"
                                    />
                                    <h4>Paul White</h4>
                                    <p>Back End Developer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="testimonial-card-2">
                                <div className="testimonial-card-text-2">
                                    <i className="flaticon-right-quotes-symbol"></i>
                                    <p>“Had a fantastic time at Glit, got to meet a lot of great people and hear some amazing talks. Thanks @glit for a super day!”</p>
                                </div>
                                <div className="testimonial-card-2-intro">
                                    <img 
                                        src={testimonialImg3} 
                                        alt="testimonial"
                                    />
                                    <h4>Jerry Monero</h4>
                                    <p>Front End Developer</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="testimonial-card-2">
                                <div className="testimonial-card-text-2">
                                    <i className="flaticon-right-quotes-symbol"></i>
                                    <p>“Had a fantastic time at Glit, got to meet a lot of great people and hear some amazing talks. Thanks @glit for a super day!”</p>
                                </div>
                                <div className="testimonial-card-2-intro">
                                    <img 
                                        src={testimonialImg4} 
                                        alt="testimonial"
                                    />
                                    <h4>Albart Cook</h4>
                                    <p>Manager</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="testimonial-card-2">
                                <div className="testimonial-card-text-2">
                                    <i className="flaticon-right-quotes-symbol"></i>
                                    <p>“Had a fantastic time at Glit, got to meet a lot of great people and hear some amazing talks. Thanks @glit for a super day!”</p>
                                </div>
                                <div className="testimonial-card-2-intro">
                                    <img 
                                        src={testimonialImg5} 
                                        alt="testimonial"
                                    />
                                    <h4>Jack Warner</h4>
                                    <p>CEO - Elan</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="testimonial-card-2">
                                <div className="testimonial-card-text-2">
                                    <i className="flaticon-right-quotes-symbol"></i>
                                    <p>“Had a fantastic time at Glit, got to meet a lot of great people and hear some amazing talks. Thanks @glit for a super day!”</p>
                                </div>
                                <div className="testimonial-card-2-intro">
                                    <img 
                                        src={testimonialImg6} 
                                        alt="testimonial"
                                    />
                                    <h4>Jerry Parker</h4>
                                    <p>Database Engineer</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Partner />

            <Footer />

            <Copyright />
        </Layout>
    )
}

export default TestimonialPage
